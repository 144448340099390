import "./assets/css/index.css";
import './App.css';
import { Route, Routes } from "react-router-dom";
import Navibar from './components/Navibar'
import Home from './view/Home'
import Nosotros from './view/Nosotros'
import DestinosTuristicos from "./view/DestinosTuristicos";


function App() {
  return (
    <div className="App">
      <Navibar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="nosotros" element={<Nosotros/>} />
        <Route path="destinos" element={<DestinosTuristicos/>} />
      </Routes>

    </div>
  );
}

export default App;
